<template>
  <div class="sell-real-time-data">
    <a-row :gutter="32">
      <a-col :span="12">
        <a-row>
          <a-col :span="24">
            <div class="title-mark">客户数据</div>
          </a-col>
        </a-row>
        <a-row class="statistic-data">
          <a-col class="statistic-box mb-24"
                 :span="6"
                 v-for="stage in customerStages"
                 :key="stage.code">
            <a-statistic class="fixed-width"
                         :title="stage.name">
              <template #formatter>
                <div class="flex justify-between">
                  <count-to :endVal="stage.allCount"
                            :duration="1000"></count-to>
                  <div v-if="stage.todayCount > 0"
                       class="text-18 text-danger">
                    <svg-icon type="iconzengchang" />{{stage.todayCount}}
                  </div>
                </div>
              </template>
            </a-statistic>
          </a-col>
        </a-row>
        <a-row class="py-24">
          <a-col :span="24">
            <a-table :columns="customerColumns"
                     :data-source="showOnlyTop10(customer)"
                     :loading="tableLoading"
                     :pagination="false">
              <template #customer="{record}">
                <avatar-column :avatar="record" />
              </template>
              <template #status="{record}">
                {{record.beforeStage}}
                <svg-icon class="inline-block transform scale-75 mx-12 text-color-999 text-18"
                          type="iconxiangyou" />
                {{record.afterStage}}
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-row>
          <a-col :span="24">
            <div class="title-mark">订单数据</div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <div class="statistic-data statistic-order">
              <div class="statistic-box">
                <a-statistic title="今日订单数">
                  <template #formatter>
                    <count-to :endVal="orderData.todayOrderNum"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>
                <a-statistic title="今日订单金额">
                  <template #formatter>
                    <count-to :endVal="Number(orderData.todayOrderAmount)"
                              :duration="1000"
                              :decimals="2"></count-to>
                  </template>
                </a-statistic>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row class="py-24">
          <a-col :span="24">
            <a-table :columns="orderColumns"
                     :data-source="showOnlyTop10(order)"
                     :loading="tableLoading"
                     :pagination="false">

              <template #staff="{record}">
                <avatar-column :avatar="{avatar:record.staffAvatar, userId:record.staffName}"
                               is-user-id />
              </template>
              <template #customer="{record}">
                <avatar-column :avatar="{avatar:record.customerAvatar,name:record.customerName,nickname:record.customerNickname}">
                </avatar-column>
              </template>
              <template #money="{record}">
                {{Number(record.payAmount).toLocaleString()}}
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import marketDataApi from "@/service/api/marketData";

import _ from "lodash";
import { Statistic } from "ant-design-vue";
import { CountTo } from "vue-count-to2";
import AvatarColumn from "@/components/AvatarColumn.vue";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "SellRealTimeData",
  components: {
    AStatistic: Statistic,
    CountTo,
    AvatarColumn,
    SvgIcon,
  },

  data() {
    const orderData = {
      todayOrderNum: 0,
      todayOrderAmount: "0",
    };
    const customerColumns = [
      {
        title: "客户",
        dataIndex: "customer",
        key: "customer",
        width: "40%",
        slots: { customRender: "customer" },
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        slots: { customRender: "status" },
      },
      {
        title: "时间",
        dataIndex: "time",
        key: "time",
      },
    ];
    const orderColumns = [
      {
        title: "成员",
        dataIndex: "staff",
        key: "staff",
        slots: { customRender: "staff" },
      },
      {
        title: "客户",
        dataIndex: "customer",
        key: "customer",
        slots: { customRender: "customer" },
      },
      {
        title: "成交金额",
        dataIndex: "money",
        key: "money",
        slots: { customRender: "money" },
      },
      {
        title: "时间",
        dataIndex: "time",
        key: "time",
        slots: { customRender: "time" },
      },
    ];
    return {
      customerStages: [],
      orderData,
      customerColumns,
      orderColumns,
      customer: [],
      order: [],
      switchButton: "0",
      tableLoading: false,
      timer: null,
    };
  },
  computed: {},

  mounted() {
    this.getCustomerAndOrderData();
    this.getCustomerAndOrderList();
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    showOnlyTop10(data) {
      if (data.length > 10) {
        const onlyData = _.slice(data, 0, 10);
        return onlyData;
      }
      return data;
    },
    async getCustomerAndOrderList() {
      this.tableLoading = true;

      this.customer = await marketDataApi.getCustomerDynamic();
      this.order = await marketDataApi.getOrderList();
      this.tableLoading = false;
      this.setInter();
    },
    setInter() {
      this.timer = setInterval(async () => {
        this.tableLoading = true;
        this.customer = await marketDataApi.getCustomerDynamic();
        this.order = await marketDataApi.getOrderList();
        this.getCustomerAndOrderData();
        this.tableLoading = false;
      }, 30000);
    },
    async getCustomerAndOrderData() {
      this.customerStages = await marketDataApi.getCustomerStage();
      this.orderData = await marketDataApi.getOrderData();
    },
  },
};
</script>
<style  scoped>
</style>