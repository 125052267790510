import {apiClient as client} from '@/shared/service/api-client';

export default {
    async getCustomerStage() {
        return await client.get('/marketData/customerStage');
    },
    async getCustomerDynamic() {
        return await client.get('/marketData/customerDynamic');
    },
    async getOrderData() {
        return await client.get('/marketData/orderData');
    },
    async getOrderList() {
        return await client.get('/marketData/orderList');
    },
    async getOrderAmountRank(params) {
        return await client.get('/marketData/orderAmountRank', {params: params});
    },
}
